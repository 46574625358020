<div class="form-container">
    <form [formGroup]="newReportForm" (ngSubmit)="onSubmit()">
    <div class="date-input">
        <label for="startDate">Start Date:</label>              
        <input formControlName="startDate" type="date" />
    </div>
    <div class="date-input">
        <label for="endDate">End Date:</label>
        <input type="date" id="endDate" class="form-control" formControlName="endDate" required />
    </div>
    <div class="dropdown">
        <label for="type">Type:</label>
        <select class="form-control" formControlName="type" required>
        <option value="MA">MA</option>
        <option value="OSB">OSB</option>
        </select>
    </div>
    <div id="submit-button">
        <div id="form-errors" *ngIf="!newReportForm.valid">
            <div class="admin-error" *ngIf="!isAdmin; else formErrors"><mat-icon>error</mat-icon>You do not have the required privileges to create a report.</div>
            <ng-template #formErrors>
                <div *ngIf="this.newReportForm.controls['startDate'].errors?.['required']"><mat-icon>error</mat-icon>Start Date is required.</div>
                <div *ngIf="newReportForm.errors?.['dateLessThan']"><mat-icon>error</mat-icon>Start date must be before end date.</div>
                <div *ngIf="this.newReportForm.controls['endDate'].errors?.['required']"><mat-icon>error</mat-icon>End Date is required.</div>
                <div *ngIf="this.newReportForm.controls['type'].errors?.['required']"><mat-icon>error</mat-icon>Writeoff type is required.</div>
            </ng-template>
        </div>
        <div *ngIf="newReportForm.valid && isAdmin">
            <button type="submit"*ngIf="!isLoading" mat-raised-button color="primary">Submit</button>
            <button type="submit"*ngIf="isLoading" mat-raised-button color="primary" class="report-button" [disabled]="true"><mat-spinner diameter="20"></mat-spinner></button>
        </div>
    </div>
    </form>
</div>