import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { Report } from '../models/report';
import { Writeoff } from '../models/writeoff';
import { Router } from '@angular/router';
import { ConfigService } from './config.service';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { MatTableDataSource } from '@angular/material/table';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(private readonly httpClient: HttpClient, private readonly router: Router, private readonly configService: ConfigService) { }

  private apiUrl = this.configService.get('WriteOffApiUrl');

  getReportDetails(id: string): Observable<Report> {
    return this.httpClient.get<Report>(`${this.apiUrl}/report/${id}`);
  }

  getWriteoffsForReport(id: string): Observable<Writeoff[]> {
    return this.httpClient.get<Writeoff[]>(`${this.apiUrl}/writeoff/report/${id}`);
  }

  applyReport(id: string): Observable<string> {
    return this.httpClient.post(`${this.apiUrl}/writeoff/report/apply/${id}`, null, { responseType: 'text' });
  }

  updateReportStatus(report: Report, status: string): Observable<boolean> {
    report.status = status;
    return this.httpClient.patch<boolean>(`${this.apiUrl}/report/${report.id}`, report);
  }

  formatReportName(report: Report): string {
      const formatDate = (date?: string | Date | null): string => {
        if (!date) return "NoDate";
        const d = typeof date === "string" ? new Date(date) : date;
        if (isNaN(d.getTime())) return "NoDate";

      const formattedDay = String(d.getUTCDate()).padStart(2, '0');
      const formattedMonth = String(d.getUTCMonth() + 1).padStart(2, '0');
      const formattedYear = d.getUTCFullYear();
      return `${formattedMonth}-${formattedDay}-${formattedYear}`;
    };
    const writeOffType = report.writeOffType && report.writeOffType.trim() !== "" ? report.writeOffType : "NoType";
    return writeOffType + "_" + formatDate(new Date(report.startDate)) + "-" + formatDate(new Date(report.endDate));
  }


  downloadReportToExcel(dataSource: MatTableDataSource<Writeoff>, reportName: string) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(reportName);

    worksheet.addRow(['Subscriber ID', 'Status', 'Contract Number', 'Bill Period', 'Amount', 'Type', 'Writeoff ID']);

    dataSource.data.forEach((row) => {
      const rowData = [
        row['subscriberId'],
        row['status'],
        row['contractNumber'],
        row['billPeriod'],
        row['amount'],
        row['type'],
        row['id']
      ]
      worksheet.addRow(rowData);
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      saveAs(blob, 'Writeoffs.xlsx');
    });
  }

  async createReport(startDate: string, endDate: string, type: string): Promise<void> {
    const postData = {
      "startDate": startDate,
      "endDate": endDate,
      "writeoffType": type,
    };

    const result: any = await firstValueFrom(this.httpClient.post(`${this.apiUrl}/report`, postData));

    this.router.navigate(['/report', result['id']]);
  }
}
