<div id="container">
    <div id="bottom-section">
        <div id="spinner-container" *ngIf="isLoadingWriteoffReport; else writeoffsSection">             
            <mat-spinner></mat-spinner>
        </div>
    <ng-template #writeoffsSection>
        <div *ngIf="errorMessage; else dataSection" class="error-message">
            {{ errorMessage }}
        </div>
        <ng-template #dataSection>
        <div *ngIf="dataSource.data != null && dataSource.data.length > 0; else noRecordsSection" class="table-container">   
        <table mat-table [dataSource]="dataSource" matSort  class="mat-elevation-z8">  
                <ng-container matColumnDef="id">
                    <th mat-header-cell class="text-bold" *matHeaderCellDef mat-sort-header> Report ID </th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container>               
                <ng-container matColumnDef="created">
                    <th mat-header-cell class="text-bold" *matHeaderCellDef mat-sort-header> Created Date </th>
                    <td mat-cell *matCellDef="let element"> {{element.created | date:'yyyy-MM-dd h:mm a'}} </td>
                </ng-container>
                <ng-container matColumnDef="numberOfWriteoffs">
                    <th mat-header-cell class="text-bold" *matHeaderCellDef mat-sort-header> Number Of WriteOffs </th>
                    <td mat-cell *matCellDef="let element"> {{ element.numberOfWriteoffs }} </td>
                </ng-container>
                <ng-container matColumnDef="startDate">
                    <th mat-header-cell class="text-bold" *matHeaderCellDef mat-sort-header> Start Date </th>
                    <td mat-cell *matCellDef="let element"> {{element.startDate | date:'yyyy-MM-dd'}} </td>
                </ng-container>
               <ng-container matColumnDef="endDate">
                    <th mat-header-cell class="text-bold" *matHeaderCellDef mat-sort-header> End Date </th>
                    <td mat-cell *matCellDef="let element"> {{element.endDate | date:'yyyy-MM-dd'}} </td>
                </ng-container> 
                <ng-container matColumnDef="writeOffType">
                    <th mat-header-cell class="text-bold" *matHeaderCellDef mat-sort-header> WriteOff Type </th>
                    <td mat-cell *matCellDef="let element"> {{ element.writeOffType }} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell class="text-bold" *matHeaderCellDef mat-sort-header> Report Status </th>
                    <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                </ng-container> 
                <ng-container matColumnDef="updatedAt">
                    <th mat-header-cell class="text-bold" *matHeaderCellDef mat-sort-header> Updated Date </th>
                    <td mat-cell *matCellDef="let element"> {{ element.updatedAt | date:'yyyy-MM-dd h:mm a' }} </td>
                </ng-container>
                <ng-container matColumnDef="viewReport">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                    <a [routerLink]="['/report/', element.id]" matTooltip="View Report"><mat-icon class ="black-icon">launch</mat-icon></a>                 
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator class="mat-paginator-sticky" #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
        </div>
    </ng-template>
    </ng-template>
    <ng-template #noRecordsSection>
        <div class="no-records-container">
        <p class="no-records-message">No WriteOff Reports found.</p>
        <p class="no-records-submessage">Start creating reports to display them here.</p>
        </div>
    </ng-template>
    </div>
</div>